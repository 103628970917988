import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import moment from 'moment';
import { Layout, BlogSection, NewsletterSection } from 'components';
import Img from 'gatsby-image';
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import readingTime from 'reading-time';

const FeauredImg = styled(Img)`
  height: 400px;
  background-size: cover;
  background: linear-gradient(
    241deg,
    rgba(66, 161, 219, 0.65) 49%,
    rgba(44, 117, 195, 0.65) 90%
  );
`;

const StyledAvatar = styled(Img)`
  border-radius: 70px;
`;

const StyledPost = styled.div`
  .post-cover {
    height: 359px;
    background-size: cover;
    background: linear-gradient(
      241deg,
      rgba(66, 161, 219, 0.65) 49%,
      rgba(44, 117, 195, 0.65) 90%
    );
    background-size: cover;
  }

  .overlay {
    background-image: linear-gradient(
      a 241deg,
      var(--main-color) 49%,
      var(--main-color-hovered) 90%
    );
    opacity: 0.65;
  }

  p {
    margin-bottom: 29px;
  }

  blockquote,
  pre {
    font-family: 'InterUI Medium', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: -1.07px;
    border-left: 5px solid var(--main-color);
    color: var(--black);
    margin-bottom: 25px;
    white-space: normal;
    padding: 0 18% 0 25px;
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-bottom: none;
  }

  h1 {
    font-size: 2.5rem;
    letter-spacing: -1px;
    line-height: 1.25;
    margin-bottom: 39px;
    @media screen and (min-width: 960px) {
      font-size: 3rem;
    }
  }

  .post-avatar {
    margin-bottom: 11px;
  }

  .post-author {
    font-family: 'InterUI Bold', sans-serif;
    font-weight: bold;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
  }

  .post-date {
    font-size: 15px;
  }

  .read-time {
    color: var(--muted);
    font-size: 15px;
  }
`;

const BlogPost = ({
  data: {
    prismicBlogPost: {
      url: path,
      data: { date, title, content, description, image, author, author_image },
    },
    prismicBlogPage: {
      data: { newsletter_heading, subscribe_button_text, mailchimp_url },
    },
  },
}) => {
  const timeToRead = readingTime(content.html);
  const url = `https://www.paynesearch.com/blog${path}`;

  const SocialFacebook = () => (
    <div>
      <FacebookShareButton url={url}>
        <FacebookIcon size={40} borderRadius={6} />
      </FacebookShareButton>
    </div>
  );
  const SocialTwitter = () => (
    <div>
      <TwitterShareButton url={url}>
        <TwitterIcon size={40} borderRadius={6} />
      </TwitterShareButton>
    </div>
  );
  const SocialLinkedIn = () => (
    <div>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={40} borderRadius={6} />
      </LinkedinShareButton>
    </div>
  );
  const SocialEmail = () => (
    <div>
      <EmailShareButton url={url}>
        <EmailIcon size={40} borderRadius={6} />
      </EmailShareButton>
    </div>
  );
  const SocialWhatsApp = () => (
    <div>
      <WhatsappShareButton url={url}>
        <WhatsappIcon size={40} borderRadius={6} />
      </WhatsappShareButton>
    </div>
  );
  const SocialReddit = () => (
    <div>
      <RedditShareButton url={url}>
        <RedditIcon size={40} borderRadius={6} />
      </RedditShareButton>
    </div>
  );

  return (
    <Layout isBlog={true}>
      <Helmet>
        <link rel="canonical" href={url} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title.text} />
        <meta property="og:description" content={description.text} />
        <meta property="og:image" content={image.url} />

        <meta name="author" content={author} />
        <meta property="article:author" content={author} />
        <meta property="article:published_time" content={date} />

        <meta name="twitter:image" content={image.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description.text} />
      </Helmet>

      <StyledPost>
        {image && <FeauredImg fluid={image.localFile.childImageSharp.fluid} />}
        <div className="uk-container uk-container-medium uk-padding-large uk-padding-remove-top uk-padding-remove-bottom">
          <div className="uk-margin-large-top" uk-grid="true">
            <div className="uk-width-1-5@m uk-width-1-1 uk-flex-first@m uk-text-left">
              <div
                uk-sticky="offset:60; bottom: #postend"
                className="uk-visible@m"
              >
                {author_image.localFile && (
                  <StyledAvatar
                    fixed={author_image.localFile.childImageSharp.fixed}
                  />
                )}
                <div className="post-author">{author}</div>
                <div className="post-date">
                  {moment(date).format('DD MMM YYYY')}
                </div>
                <div className="read-time">{timeToRead.text}</div>
              </div>

              <div className="uk-hidden@m">
                {author_image.localFile && (
                  <StyledAvatar
                    fixed={author_image.localFile.childImageSharp.fixed}
                  />
                )}
                <div className="post-author">{author}</div>
                <div className="post-date">
                  {moment(date).format('DD MMM YYYY')}
                </div>
                <div className="read-time">{timeToRead.text}</div>
              </div>
            </div>

            <div className="uk-width-expand">
              <h1>{title.text}</h1>
              <div dangerouslySetInnerHTML={{ __html: content.html }} />
            </div>

            <div className="uk-width-1-5@m uk-width-1-1 uk-visible@m">
              <div
                className="share uk-child-width-1-1 uk-float-right uk-grid-small"
                uk-grid="true"
                uk-sticky="offset:60; bottom: #postend"
              >
                <SocialTwitter />
                <SocialFacebook />
                <SocialLinkedIn />
                <SocialEmail />
                <SocialReddit />
                <SocialWhatsApp />
              </div>
            </div>

            <div className="uk-width-1-5@m uk-width-1-1 uk-margin-xlarge-top uk-hidden@m">
              <div
                className="share uk-child-width-auto uk-flex uk-flex-center uk-grid-small"
                uk-grid="true"
              >
                <SocialTwitter />
                <SocialFacebook />
                <SocialLinkedIn />
                <SocialEmail />
                <SocialReddit />
                <SocialWhatsApp />
              </div>
            </div>
          </div>
        </div>
      </StyledPost>

      <div id="postend" />

      <NewsletterSection
        title={newsletter_heading.text}
        newsletter_url={mailchimp_url.url}
        button_text={subscribe_button_text}
      />

      <BlogSection bg="white" ctaText={'View all posts'} />
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPostByID($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      url
      data {
        date
        title {
          text
        }
        description {
          text
        }
        content {
          html
        }
        image {
          url
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        author
        author_image {
          localFile {
            childImageSharp {
              fixed(width: 70, height: 70) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    prismicBlogPage {
      data {
        newsletter_heading {
          text
        }
        subscribe_button_text
        mailchimp_url {
          url
        }
      }
    }
  }
`;
